<script>
    import { getContext } from 'svelte';
    import {writable} from "svelte/store";
    import {generateUuid} from "../../common/utils.js";

    export let headerText;
    export let padding = true;
    export let theme;
    export let startOpen = false;

    const uuid = generateUuid();
    const active = getContext("active") || writable(null); // Fallback in case we are not in an ExpansionBoxGroup

    $: isActive = $active === uuid;
    $: {
        if (startOpen) {
            $active = uuid;
        }
    }

    function toggleExpanded(evt) {
        evt.stopImmediatePropagation();
        if (isActive) {
            $active = null;
        } else {
            $active = uuid;
        }
    }
</script>

<div class="expansion-box {theme ? theme : ''}" class:open={isActive}>
    <button
        class='expansion-box--toggle'
        aria-expanded='false'
        on:click={toggleExpanded}
    >
        <h2>{headerText}</h2>
        <div class='chevron'></div>
    </button>
        <div class="content {padding ? '' : 'no-padding'}"
             role="region">
            <div class="item">
                <slot />
            </div>
        </div>
</div>

<style>
    @import '../../common/colors.css';

    div.expansion-box {
        background: var(--white);
        display: block;
        border-top: 3px solid var(--lightGray);
        border-bottom: 3px solid var(--lightGray);
        margin-top: -3px;
        position: relative;
    }

    div.expansion-box.narrow:last-of-type{
        margin-bottom: 20px;
    }

    div.expansion-box.narrow .expansion-box--toggle {
        padding: 0 5px;
    }

    @media screen and (max-width: 500px) {
        div.expansion-box {
            box-sizing: border-box;
            width: calc(100% + 40px);
            margin-left: -20px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    div.expansion-box .expansion-box--toggle {
        border: none;
        background: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 20px;
    }

    div.expansion-box h2 {
        font-size: 18px;
        line-height: 30px;
        margin: 0;
        cursor: pointer;
        text-align: left;
    }

    div.expansion-box .chevron {
        position: relative;
        height: 48px;
        width: 48px;
    }

    div.expansion-box .chevron::after {
        content: '';
        border-style: solid;
        border-color: var(--darkGray);
        border-width: 0 3px 3px 0;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        position: absolute;
        left: 20px;
        top: 20px;
        transition: transform 150ms ease;
    }

    div.expansion-box.open > button .chevron::after {
        transform: rotate(225deg);
    }

    div.expansion-box .content {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 150ms ease, padding-top 150ms ease, margin-top 150ms ease, opacity 150ms ease;
        box-sizing: border-box;
        width: 100%;
        opacity: 0;
        overflow: hidden;
        padding-top: 0;
    }

    div.expansion-box.open > .content {
        border-top: 1px solid var(--lightGray);
        opacity: 1;
        grid-template-rows: 1fr;
        padding-bottom: 40px;
        padding-top: 40px;
    }

    div.expansion-box .content .item {
        overflow: hidden;
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid var(--lightGray);
    }

    div.expansion-box:not(.narrow) .content .item{
        padding-left: 100px;
    }

    @media screen and (max-width: 500px) {
        div.expansion-box .content {
            width: calc(100% - 40px);
        }

        div.expansion-box:not(.narrow) .content .item {
            padding-left: 0
        }
    }

    div.expansion-box .content .item:last-child {
        border-bottom: 0 none;
    }
  
</style>