<script>
  import { getWithCredentials } from "~/common/fetchHelpers.js";
  import { onMount, onDestroy } from "svelte";
  import spinner from "@/images/icons/spinner.svg?raw";
  import InlineSVG from "@/components/InlineSVG.svelte";
  import MessageBox from "~/components/MessageBox.svelte";
  export let credentialUUID = "";
  let status = "";
  let interval;

  onMount(() => {
    refreshSendStatus();
    if (!isCompleted(status)) {
      interval = setInterval(() => refreshSendStatus(), 1000);
    }
  });

  onDestroy(() => clearInterval(interval));

  function refreshSendStatus() {
    getWithCredentials(
      `/aid/logg_inn/status_sending_engangskode/${credentialUUID}`,
    )
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(
            new Error(
              "Kunne ikke hente status på sending av engangskode. Du kan forsøke å laste siden på nytt.",
            ),
          );
        }
        return response.json();
      })
      .then((json) => {
        const updatedStatus = json.transport_status;
        if (isCompleted(updatedStatus)) {
          clearInterval(interval);
        }
        status = updatedStatus;
      });
  }

  function isCompleted(status) {
    return status === "sent" || status === "error" || status === "expired";
  }
</script>

{#if status === "sent"}
  <slot />
{:else if status === "error" || status === "expired"}
  <MessageBox type="error"
    >{@html 'Sending av engangskode feilet. Prøv igjen eller kontakt kundeservice på <a href="mailto: kundeservice@aid.no">kundeservice@aid.no</a>.'}</MessageBox
  >
{:else}
  <InlineSVG withRotation={true} svg={spinner} --svg-square-size="24px" />
{/if}
