<script>
    import LabelledFormInput from "./LabelledFormInput.svelte";
    import StyledInputField from "./StyledInputField.svelte";
    import {onMount, tick} from "svelte";

    export let label;

    const name='code';
    let value='';
    let wrapper;

    onMount(async () => {
        try {
            if (!window.OTPCredential) {
                return;
            }

            const abort = new AbortController();
            const form = wrapper.closest('form');
            if (form) {
                form.addEventListener('submit', (e) => {
                    abort.abort();
                });
            }
            setTimeout(() => {
                // abort after two minutes
                abort.abort();
            }, 2 * 60 * 1000);

            navigator.credentials.get({
                signal: abort.signal,
                otp: {
                    transport: ["sms"]
                }
            }).then(async (otp) => {
                value = otp.code;
                await tick();
                form.submit();
            }).catch((e) => {console.error(e)});
        } catch (e) {
            console.error(e);
        }
    });
</script>

<div class="wrapper" bind:this={wrapper}>
    <LabelledFormInput {name} {label}>
        <StyledInputField {name} {value} type="text" autocomplete="one-time-code" inputmode="numeric" on:change />
    </LabelledFormInput>
</div>
