<script>
</script>

<a href="./" on:click|preventDefault={(e) => {e.target.closest('form').submit()}}><slot /></a>

<style>
    @import "../../common/colors.css";

    a {
        text-decoration: underline;
        color: var(--linkColor);
        cursor: pointer;
    }
</style>