<script>
    import {performRegistrationCeremony} from "~/common/webAuthn.js";
    import {UAParser} from "ua-parser-js";
    import {createEventDispatcher} from "svelte";
    import Form from "@/components/Form.svelte";
    import WebauthnButton from "~/components/webauthn/WebauthnButton.svelte";

    export let prio = true;
    let browser = new UAParser();
    let nick = `${browser.getBrowser().name} ${browser.getOS().name}`

    const dispatch = createEventDispatcher();

    function register(evt) {
        evt.preventDefault();
        dispatch('begin')
        const form = new FormData(evt.currentTarget);
        performRegistrationCeremony(form.get('nick'))
            .then(() => {
                evt.target.reset();
                dispatch("complete");
                window.dispatchEvent(new CustomEvent('aid:webauthnKeyAdded'));
            })
            .catch((error) => {
                dispatch('error', {
                    error,
                    reason: "Kunne ikke registrere nøkkel",
                })
                console.error("Error occurred during registration ceremony", error);
            });
    }
</script>

<Form on:submit={register}>
    <input type="hidden" name="nick" value={nick}>
    <WebauthnButton {prio} logName="webauthn_register"><slot>Registrer passnøkkel</slot></WebauthnButton>
</Form>
